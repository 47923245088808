


































import { Vue, Component } from 'vue-property-decorator';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import CoursesList from '@/components/coursesPlanner/lists/CoursesList.vue';
import { namespace } from 'vuex-class';
import PaginationFooter from '@/components/paginationFooter/PaginationFooter.vue';
import { SearchObject } from '@/models/GlobalModels';
import { SearchType } from '@/enums/globalUI';
import Loader from '@/components/shared/Loader.vue';
import { CourseInfo } from '@/models/CoursesModels';

const searchModule = namespace('SearchModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    BreadCrumbs,
    CoursesList,
    Loader,
    PaginationFooter,
  },
})
export default class CoursePlannerLanding extends Vue {
  @searchModule.Getter
  public totalElements!: number;

  @searchModule.Getter
  public totalPages!: number;

  @searchModule.Getter
  public actualPage!: number;

  @searchModule.Getter
  public itemsPerPageOptions!: number;

  @searchModule.Getter
  public searchItems!: CourseInfo[];

  @coursesTemplateModule.Getter
  public itemsPerPageCourseInfo!: number;

  @searchModule.Mutation
  public setActualPage!: (page: number) => void;

  @searchModule.Mutation
  public setItemsPerPage!: (itemsPerPage: number) => void;

  @coursesTemplateModule.Mutation
  public setItemsPerPageCourseInfo!: (itemsPerPage: number) => void;

  @searchModule.Action
  public fetchItemsPerPage!: (searchType: SearchType) => Promise<void>;

  @searchModule.Action
  public searchItemsReq!: (search: SearchObject) => Promise<void>;

  private isLoading: boolean = false;

  protected created(): void {
    this.isLoading = true;
    this.fetchData();
  }

  private fetchData() {
    this.setItemsPerPage(this.itemsPerPageCourseInfo);
    this.fetchItemsPerPage(SearchType.COURSESPLANNER).then(() => {
      this.isLoading = false;
    });
  }

  private courseAddedHandler() {
    this.fetchData();
  }

  private updateItemsPerPage(newValue: number | string) {
    let itemsPerPage: number = 0;
    if (newValue !== 'ALL') {
      itemsPerPage = typeof newValue === 'string' ? parseInt(newValue, 10) : newValue;
    } else {
      itemsPerPage = this.totalElements;
    }
    this.setItemsPerPageCourseInfo(itemsPerPage);
    this.setItemsPerPage(this.itemsPerPageCourseInfo);
    this.setActualPage(1);
    this.fetchData();
  }

  private nextPage() {
    if (this.actualPage + 1 <= this.totalPages) {
      this.setActualPage(this.actualPage + 1);
      this.fetchData();
    }
  }

  private previousPage() {
    if (this.actualPage - 1 >= 1) {
      this.setActualPage(this.actualPage - 1);
      this.fetchData();
    }
  }
}
